import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  CLEAR_CUSTOMERS,
  PATCH_UPDATE_CUSTOMER_PREFERENCES,
  GET_CUSTOMER_PREFERENCES,
  GET_CUSTOMER_INFO,
  RESET_CUSTOMER_INFO_RETRIEVED,
} from "../actions/types";

import { translateCustomerDataAWS } from "../../utils/translators";

const initialState = {
  customers: [],
  isEmptyList: false,
  showLoader: false,
  error: false,
  isCustomerInfoRetrieved: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST: {
      return {
        ...state,
        showLoader: true,
        error: false,
      };
    }
    case FETCH_CUSTOMERS_SUCCESS: {
      let customers = translateCustomerDataAWS(action.payload);
      return {
        ...state,
        customers: customers,
        showLoader: false,
        isEmptyList: customers.length === 0,
        error: false,
      };
    }
    case FETCH_CUSTOMERS_ERROR: {
      return {
        ...state,
        showLoader: false,
        error: true,
      };
    }
    case CLEAR_CUSTOMERS: {
      return initialState;
    }
    case PATCH_UPDATE_CUSTOMER_PREFERENCES: {
      let payload = action.payload;
      
      let indexOfCustomerToBeUpdated = state.customers.findIndex(
        (customer) => customer.clientID === payload.clientID
      );
      let customerToBeUpdated = state.customers.filter(
        (customer) => customer.clientID === payload.clientID
      );
      let updatedCustomerList = state.customers.filter(
        (customer) => customer.clientID !== payload.clientID
      );
      let updatedCustomer = null;
      if (customerToBeUpdated.length > 0) {
        updatedCustomer = customerToBeUpdated[0];
        updatedCustomer.doNotText = payload.donotText;
        updatedCustomer.textStatus = payload.donotText ? "D" : "";
      }
      //add customer at appropriate index
      updatedCustomerList.splice(
        indexOfCustomerToBeUpdated,
        0,
        updatedCustomer
      );
      return {
        ...state,
        customers: updatedCustomerList
      };
    }
    case GET_CUSTOMER_PREFERENCES: {
      let payload = action.payload.data;
      let indexOfCustomerToBeUpdated = state.customers.findIndex(
        (customer) => customer.clientID === payload.clientID
      );
      let customerToBeUpdated = state.customers.filter(
        (customer) => customer.clientID === payload.clientID
      );
      let updatedCustomerList = state.customers.filter(
        (customer) => customer.clientID !== payload.clientID
      );
      let updatedCustomer = null;
      if (customerToBeUpdated.length > 0) {
        updatedCustomer = customerToBeUpdated[0];
        updatedCustomer.doNotText = payload.donotText;
        updatedCustomer.textStatus = payload.donotText ? "D" : "";
      }
      //add customer at appropriate index
      updatedCustomerList.splice(
        indexOfCustomerToBeUpdated,
        0,
        updatedCustomer
      );
      return {
        ...state,
        customers: updatedCustomerList,
      };
    }

    case GET_CUSTOMER_INFO: {
      let payload = action.payload;
      return {
        ...state,
        clientDetails: payload,
        isCustomerInfoRetrieved: true,
      };
    }
    case RESET_CUSTOMER_INFO_RETRIEVED: {
      return {
        ...state,
        isCustomerInfoRetrieved: false,
      };
    }
    default:
      return state;
  }
}
