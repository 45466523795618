import { SET_LEGACY_WEBSOCKET } from "../redux/actions/types.js";
import { dispatchWebsocketData } from "../redux/actions/webSocketActions.js";
import { wsReadyState } from "./apiGatewayWebsocketHandler.js";

export const legacyWebsocketHandler = {
  initialReconnectDelay: 1000,
  currentReconnectDelay: 10,
  maxReconnectDelay: 16000,
  socket: null,
  connectionString: null,
  connectionParms: null,
  readyState: wsReadyState.CLOSED,
  connect: function (wsURI, _parma) {
    this.connectionString = wsURI;
    this.connectionParms = _parma;
    this.createWebsocket();
    this.socket.addEventListener("open", this.onWebsocketOpen(this));
    this.socket.addEventListener("close", this.onWebsocketClose(this));
    this.socket.addEventListener("error", this.onWebsocketError(this));
    return this.socket;
  },
  createWebsocket: function () {
    this.socket = new WebSocket(this.connectionString, this.connectionParms);
    this.readyState = this.socket.readyState;
    this.logStatus("Create");
  },
  refresh: function (param) {
    // Create new WS connection and close the previous socket.
    const prevSocket = this.socket;
    this.logStatus("Refresh");
    this.connect(this.connectionString, param);
    prevSocket.close();
  },
  onWebsocketOpen: function () {
    this.currentReconnectDelay = this.initialReconnectDelay;
    this.readyState = this.socket.readyState;
    this.dispatchEvent();
    this.readyState = this.socket.readyState;
    this.logStatus("Open");
  },
  onWebsocketClose: function () {
    if (this.socket.readyState > wsReadyState.OPEN) {
      this.logStatus("Close");
      this.dispatchEvent();
      this.socket = null;
      this.readyState = wsReadyState.CLOSED;
      // Add anything between 0 and 3000 ms to the delay.
      setTimeout(() => {
        this.reconnectToWebsocket();
      }, this.currentReconnectDelay + Math.floor(Math.random() * 3000));
    }
    this.dispatchEvent();
  },
  reconnectToWebsocket: function () {
    if (this.currentReconnectDelay < this.maxReconnectDelay) {
      this.currentReconnectDelay *= 2;
    }
    this.logStatus("Reconnect");
    this.connect(this.connectionString, this.connectionParms);
  },
  onWebsocketError: function (event) {
    this.readyState =
      typeof this.socket !== "undefined" ? this.socket.readyState : 3;
    this.logStatus("on error Status");
  },
  logStatus: function (_status_) {
    console.log(
      `WebSocket ${_status_} -  Connection: ${this.connectionString}, Delay: ${this.currentReconnectDelay}, ReadyState: ${this.readyState}`
    );
  },
  dispatchEvent: function () {
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent("SFC_WebSocketStatus", {
          bubbles: true,
          detail: {
            reconnectDelay: this.currentReconnectDelay,
            state: this.readyState,
          },
        })
      );
      dispatchWebsocketData(SET_LEGACY_WEBSOCKET, {
        socket: this.socket,
        readyState: this.readyState,
      });
    }, 2000);
  },
};
