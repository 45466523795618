import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmptyState from "components/EmptyState";
import MessageList from "./MessageList";
import SendMessage from "components/SendMessage";
import { getMmsUrl } from "redux/actions/interactionsActions";
import MultipleMatchDropdown from "./MultipleMatchDropdown";
import { getDateTime } from "../../utils/translators";
import { wsReadyState } from "../../utils/apiGatewayWebsocketHandler";
import Notification from "../Notification";
import { mainAction,logUIErrors,archiveInteraction, fetchMessages } from "redux/actions/interactionsActions";
import { CLEAR_CURRENT_INTERACTION } from "redux/actions/types";
import { archiveInteractionAndDeleteCache } from "../../redux/actions/customerSearchAction";
import { toast } from "react-toastify";

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInteractionID: "",
      messageCount: 0,
      messagePreviewText: "",
      displayAttachmentError: false,
      errorMessage: ""
    };
    this.closeErrorBanner = this.closeErrorBanner.bind(this);
    this.handleAttachmentError = this.handleAttachmentError.bind(this);
  }

  componentDidMount() {
    if (window.oneX) {
      window.oneX.Icons.init();
    }
    this.setState({
      currentInteractionID: this.props.currentInteractionId,
      messageLength: this.props.interactions.chatLog.length,
    });
  }

  componentDidUpdate() {
    if (
      this.refs.interactions &&
      (this.props.currentInteractionId !== this.state.currentInteractionID ||
        this.state.messageLength !== this.props.interactions.chatLog.length)
    ) {
      this.scrollToBottom();
      this.setFocus();
      this.setState({
        currentInteractionID: this.props.currentInteractionId,
        messageLength: this.props.interactions.chatLog.length,
      });
    }
  }

  scrollToBottom() {
    this.refs.interactions.scrollTo(0, document.body.scrollHeight + 2000);
  }

  setFocus() {
    const _listCount = this.props.interactions.chatLog.length;
    if (_listCount > 0) {
      this.refs.interactions.childNodes[_listCount - 1]
        .getElementsByClassName("message-pane")[0]
        .focus();
    }
  }

  onClickClose = () => {
    this.props.actions.mainAction(CLEAR_CURRENT_INTERACTION, null);
  };

  onClickDeleteCache = async (clientId, mobilePhone) => {
    await this.props.actions.archiveInteractionAndDeleteCache(
      this.props.agentId,
      this.props.currentInteractionId,
      clientId,
      this.props.loggedInAssociateId,
      mobilePhone
    );
    //Reload page to get new interaction
    window.location.reload();
  };

  refreshMessages= async () => {
    toast.info('Refreshing messages.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000
    });

    await this.props.actions.fetchMessages(
      this.props.currentInteractionId,
      this.props.agentId,
      this.props.currentInteraction.clientId,
      this.props.currentOfficeMembers, // current office members
      this.props.loggedInAssociateId,
      false
    );
  };

  getTempMessage(tempSendMessag) {
    const tempMessage = {
      messageSenderName: "",
      messageDate: getDateTime.formatedDate(),
      messageTime: getDateTime.formatedTime(),
      message: tempSendMessag,
      messageType: "outbound",
      messageSender: "agent",
    };
    return tempMessage;
  }

  closeErrorBanner() {
    this.setState({
      displayAttachmentError: false,
      errorMessage: "",
    });
  }

  handleAttachmentError(errorType) {
    let errorMessage;
    switch (errorType) {
      case "invalid_file":
        errorMessage = {
          heading: "Your message cannot be sent",
          body: (
            <>
              There are file type and size limitations:
              <br />
              <ul>
                <li>Accepted file types: PDF, JPG, PNG, GIF</li>
                <li>Files can be no larger than 1MB</li>
              </ul>
            </>
          ),
        };
        break;
      case 403:
        errorMessage = {
          heading: "Your message could not be sent",
          body: (
            <>
              Access denied. Please refresh and try again. If the problem
              persists, contact the Customer Care Center – Support Center at{" "}
              <b>877-889-2294</b>.
            </>
          ),
        };
        break;
      case 400:
      case 401:
      case 404:
      case 500:
      default:
        errorMessage = {
          heading: "Your message could not be sent",
          body: (
            <>
              File upload failed. Please refresh and try again. If the problem
              persists, contact the Customer Care Center – Support Center at{" "}
              <b>877-889-2294</b>.
            </>
          ),
        };
        break;
    }
    this.setState({
      displayAttachmentError: true,
      errorMessage: errorMessage,
    });
  }

  render() {
    const custName = (currentInteraction) => {
      let custNameDisplay = "";
      if (currentInteraction.clientType === "client") {
        custNameDisplay = currentInteraction.customerName;
      }
      return custNameDisplay;
    };

    const screenAlertMessage = (currentInteraction) => {
      let screenBannerMessage = "";
      let clientType = currentInteraction.clientType;

      switch (clientType) {
        case "prospect": {
          screenBannerMessage = (
            <a
              href={process.env.REACT_APP_ECRM_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="add-prospect-anchor">
              Add Prospect/Update Customer
            </a>
          );
          break;
        }
        case "client": {
          if (currentInteraction.casl) {
            screenBannerMessage = <p>Subject to CASL. Contact by phone.</p>;
          } else {
            if (currentInteraction.customerOfAnotherAgent) {
              screenBannerMessage = <p>Customer of Another Agent</p>;
            }
            if (currentInteraction.clientId === "MORETHAN100") {
              screenBannerMessage = (
                <div>
                  <p>
                    Customer Data Issue - this customer phone number matches
                    more than 100 customer records.
                  </p>
                  <p>
                    To reply, archive this message, search for the customer by
                    name, and initiate a new text.
                  </p>
                </div>
              );
            }
            if (currentInteraction.doNotText) {
              screenBannerMessage = (
                <p>Customer is Do Not Text - Request customer text "Unstop"</p>
              );
            }
            if (
              currentInteraction.customerOfAnotherAgent &&
              currentInteraction.doNotText
            ) {
              screenBannerMessage = (
                <p>
                  Customer of Another Agent <br></br>Customer is Do Not Text -
                  Request customer text "Unstop"
                </p>
              );
            }
          }
          //  Handling ECRM customer number mismatch
          if (
            currentInteraction.ecrmMobile &&
            currentInteraction.ecrmMobile !== "" &&
            this.props.interactions.currentInteraction.webSocketJSON
              .destination[0].data.phone !== currentInteraction.ecrmMobile
          ) {
            screenBannerMessage = (
              <p>
                A change was detected to this phone number in ECRM.&nbsp;
                <a
                  href="javascript:void(0);"
                  onClick={(e) =>
                    this.onClickDeleteCache(
                      currentInteraction.clientId,
                      this.props.interactions.currentInteraction.webSocketJSON
                        .destination[0].data.phone
                    )
                  }
                >
                  Click here
                </a>
                &nbsp;so system can archive texts to old number and refresh
                data. New number can be texted after 5 minutes.
              </p>
            );
          } else {
            if (
              this.props.interactions.currentInteraction.webSocketJSON
                .destination[0].data.phone === ""
            ) {
              screenBannerMessage = (
                <p>No mobile number found. Submit service ticket.</p>
              );
            }
          }

          if (currentInteraction.virtualAssistantActive === true) {
            screenBannerMessage = <p>Virtual Assistant is active.</p>;
          }

          break;
        }
        case "multi-match": {
          screenBannerMessage = (
            <MultipleMatchDropdown
              multiMatchClientInfo={
                this.props.interactions.currentInteraction.multiMatchClientIDs
              }
            />
          );
          break;
        }
        default: {
          screenBannerMessage = "";
        }
      }
      return screenBannerMessage;
    };
    return (
      <>
        <div className="-sfc-message-index">
          {this.props.currentInteractionId ? (
            <>
              <div
                className={
                  this.state.displayAttachmentError
                    ? "-oneX attachment-error-banner"
                    : "-oneX-hidden"
                }>
                <section
                  id="gblAlrt01"
                  className="-oneX-global-alert-container -oneX-container"
                  aria-label="alert">
                  <h2
                    id="global-alert-Hdr"
                    tabIndex="-1"
                    className="-oneX-global-alert-header">
                    {this.state.errorMessage.heading}
                  </h2>
                  <div className="-oneX-global-alert-body">
                    {this.state.errorMessage.body}
                  </div>
                  <button
                    className="-oneX-close-X-button"
                    aria-label="close"
                    onClick={this.closeErrorBanner}>
                    <span className="-oneX-icon--close"></span>
                  </button>
                </section>
              </div>
              <div className="client-display-container">
                {custName(this.props.interactions.currentInteraction) !==
                  "" && (
                  <div
                    id="clientName"
                    className="client-name-display-container -oneX-heading--h5">
                    {custName(this.props.interactions.currentInteraction)}
                  </div>
                )}
                {screenAlertMessage(
                  this.props.interactions.currentInteraction
                ) !== "" && (
                  <div className="client-alert-display-container -oneX-heading--h5">
                    {screenAlertMessage(
                      this.props.interactions.currentInteraction
                    )}
                  </div>
                )}
              </div>

              <div
                ref={"interactions"}
                className="-sfc-interaction-panel -display-flex-col clearfix"
                role="list">
                {this.props.interactions.chatLog.map((message, index) => {
                  return (
                    <div key={index + "list"} ref={"messageList"}>
                      <MessageList
                        key={index}
                        message={message}
                        token={this.props.token}
                        agentId={this.props.agentId}
                        getMmsUrl={this.props.actions.getMmsUrl}
                        index={index}
                        customerInitials={
                          this.props.interactions.currentInteraction
                            .customerInitials
                        }
                      />
                    </div>
                  );
                })}
                <button
                  id="closeMessagePanelX"
                  className="-oneX-close-X-button"
                  aria-label="hide message panel"
                  tabIndex="0"
                  onClick={this.onClickClose}>
                  <span className="-oneX-icon--close"></span>
                </button>
                {this.props.interactions.currentInteraction.tempSendMessage && (
                  <section
                    tabIndex="-1"
                    aria-live="polite"
                    data-scrollto={this.scrollToBottom()}>
                    <MessageList
                      key={999}
                      message={this.getTempMessage(
                        this.props.interactions.currentInteraction
                          .tempSendMessage
                      )}
                      token={this.props.token}
                      agentId={this.props.agentId}
                      getMmsUrl=""
                      index={999}
                      customerInitials=""
                    />
                  </section>
                )}
                <section tabIndex="-1" aria-live="polite">
                  <div className="hide-offscreen">Message Delivery Status</div>
                  {/* Messages Status: sending, delayed, sent, failed */}
                  <div
                    tabIndex="-1"
                    className={
                      "message-delivery status-" +
                      this.props.interactions.currentInteraction
                        .messageDeliveryStatus
                    }>
                    {/* {this.props.interactions.currentInteraction.tempSendMessage}              */}
                    {this.props.interactions.currentInteraction
                      .messageDeliveryStatus === "sent" ||
                    this.props.interactions.currentInteraction
                      .messageDeliveryStatus === "failed" ? (
                      <>
                        &nbsp;
                        <p>
                          {
                            this.props.interactions.currentInteraction
                              .messageDeliveryStatus
                          }
                          {
                            this.props.interactions.currentInteraction
                              .messageDeliveryErrorType
                          }
                        </p>
                        {/* <progress /> */}
                      </>
                    ) : null}
                    {/* `${this.props.interactions.currentInteraction.messageDeliveryStatus}
                         ${this.props.interactions.currentInteraction.messageDeliveryErrorType}` : '' */}
                  </div>
                </section>
              </div>
              {(this.props.legacyWebSocketReadyState !== wsReadyState.OPEN ||
                this.props.apiGatewayReadyState !== wsReadyState.OPEN) && (
                <div className="-oneX-row">
                  <div className="-oneX-col-lg-10">
                    <Notification type="informational" className="notification">
                      SF Connect is offline. Text messages may be sent, but incoming texts for this conversation will not appear unless you refresh.
                    </Notification>
                  </div>
                  <div className="-oneX-col">
                        <button
                          id="refreshMessagesForInteractionButton"
                          ref={"refreshMessagesForInteractionButton"}
                          className="-oneX-btn-primary -oneX-btn-small"
                          onClick={(e) => this.refreshMessages()}>
                          Refresh
                        </button>
                  </div>
                </div>
              )}
              <SendMessage onAttachmentError={this.handleAttachmentError} />
            </>
          ) : (
            <EmptyState history={this.props.history} />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentInteractionId:
      state.interactionsReducer.currentInteraction.interactionId,
    currentInteraction: state.interactionsReducer.currentInteraction,
    interactions: state.interactionsReducer,
    agentId: state.userInfoReducer.officeAssociateId,
    token: state.authReducer.token,
    legacyWebSocketReadyState: state.webSocketReducer.legacyReadyState,
    apiGatewayReadyState: state.webSocketReducer.apiGatewayReadyState,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
    currentOfficeMembers: state.userInfoReducer.currentOfficeMembers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getMmsUrl: getMmsUrl,
        mainAction,
        archiveInteractionAndDeleteCache,
        logUIErrors,
        archiveInteraction,
        fetchMessages
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
