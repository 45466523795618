import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";
import {
  deleteTextTemplate,
  updateTextTemplate,
  clearCustomTemplateValues
} from "../../redux/actions/customTemplatesActions";
import { toast } from "react-toastify";

class CustomTemplateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      inputContent: this.props.item.content,
      officePhone: this.props.displayedOfficePhone,
      panel: {
        visible: "",
        expansionHeight: { height: "41.6px" }
      }
    };
  }

  onClickHandler = (track) => {
    const _wordTrack = track;
    if (typeof _wordTrack === "string") {
      const _newWordTrack = _wordTrack
        .replace(/(&lt;)/g, "<")
        .replace(/(&gt;)/g, ">")
        .replace(/(&amp;)/g, "&")
        .replace(/<office phone number>/g, this.state.officePhone)
        .replace(/<office phone#>/g, this.state.officePhone);

      const { cursorPos } = this.props.textMessageBox;
      const _startMessage = this.props.messageBody
        ? this.props.messageBody.slice(0, cursorPos.start)
        : "";
      const _endMessage = this.props.messageBody
        ? this.props.messageBody.slice(cursorPos.end)
        : "";
      let updatedData = { ...this.props.item };
      if (updatedData.content) {
        updatedData.content =  _newWordTrack;
      }
      this.props.actions.mainAction("SELECTED_TEMPLATE", {
        selectedTemplate: {
          type: "custom",
          data: updatedData
        }
      });
      this.props.actions.mainAction(
        "UPDATE_CHAT",
        _startMessage + _newWordTrack + _endMessage
      );

      this.hideDrawer();
      // Give the document focus
      setTimeout(() => {
        const _textArea = document.getElementById("conTA");
        _textArea.focus();
      }, 1000);
    }
  };

  hideDrawer = () => {
    this.props.actions.clearCustomTemplateValues("CLEAR_CUSTOM_TEMPLATE", null);
    window.oneX.Drawer.hideDrawer();
  };

  handleDeleteConfirm = (toastId) => {
    this.props.actions.deleteTextTemplate(
      this.props.officeAssociateId,
      this.props.item.title,
      this.props.item.category
    );
    toast.dismiss(toastId);
  };

  onClickDeleteTemplate = () => {
    const toastId = toast.info(
      <div>
        <p>Are you sure you want to delete {this.props.item.title} ?</p>
        <button onClick={() => this.handleDeleteConfirm(toastId)}>Yes</button>
        &nbsp;&nbsp;
        <button onClick={() => toast.dismiss(toastId)}>No</button>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        pauseOnHover: true,
        progress: undefined,
        closeButton: true
      }
    );
  };

  onClickUpdateTemplate = () => {
    this.props.actions.updateTextTemplate(
      this.props.officeAssociateId,
      this.props.loggedInAssociateId,
      this.state.inputContent,
      this.props.item.title,
      this.props.item.category
    );
    this.setState({ toggle: false });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.templateCurrentStatus !== this.props.templateCurrentStatus ||
      prevProps.customTextTemplates !== this.props.customTextTemplates ||
      prevProps.item !== this.props.item ||
      prevProps.index !== this.props.index
    ) {     
      this.forceUpdate();
    }
  }

  render() {
    const { item, index } = this.props;
    
    return (
      <div class="-oneX-panel--expansion--nested">
        <div class="-oneX-panel-control">          
            <b>{item.title}</b>          
        </div>
        <div
          id={"template_" + item.title + "_" + index}
          tabindex={index}
          class="-oneX-panel-content"
        >
          {!this.state.toggle ? (
            <div>
              {item.content}
              <div className="-oneX-row -oneX-no-gutters bottom-nav ">
                <div className="-oneX-col-8" />
                <a                  
                  class="-oneX-link--block -oneX-link-tertiary"
                  href="javascript:void(0);"
                  onClick={(e) => this.onClickHandler(item.content)}
                >
                  Select
                </a>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;              
                <a
                  class="-oneX-link--block -oneX-link-tertiary"
                  href="javascript:void(0);"
                  onClick={(event) => {
                    this.setState({ 
                      toggle: true,
                      inputContent : item.content
                     });
                  }}
                >
                  Edit
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  class="-oneX-link--block -oneX-link-tertiary"
                  href="javascript:void(0);"
                  onClick={(e) => this.onClickDeleteTemplate()}
                >
                  Delete
                </a>
              </div>
            </div>
          ) : (
            <div className="-oneX-row -oneX-no-gutters bottom-nav ">
              <textarea
                class="-oneX-textfield--conversational-input"
                value={this.state.inputContent}
                onChange={(event) => {
                  this.setState({ inputContent: event.target.value });
                }}
                maxLength={280}
              ></textarea>
              <div class="-oneX-textarea-charRemaining">
                ({280 - this.state.inputContent.length}) characters remaining
              </div>
              <div className="-oneX-col-10" />
              <a
                class="-oneX-link--block -oneX-link-tertiary"
                href="javascript:void(0);"
                onClick={(e) => this.onClickUpdateTemplate()}
              >
                Save
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                class="-oneX-link--block -oneX-link-tertiary"
                href="javascript:void(0);"
                onClick={(event) => {
                  this.setState({ toggle: false });
                }}
              >
                Cancel
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.interactionsReducer.textMessageBox,
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone,
    agentId: state.userInfoReducer.officeAssociateId,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    templateCurrentStatus: state.customTemplatesReducer.templateCurrentStatus,
    customTextTemplates: state.customTemplatesReducer.customTextTemplates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {        
        mainAction,
        updateTextTemplate,
        deleteTextTemplate,
        clearCustomTemplateValues
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplateItem);
