import {
  SET_LEGACY_WEBSOCKET,
  SET_API_GATEWAY_WEBSOCKET,
  SET_CONNECTED_TO_API_GATEWAY_WEBSOCKET,
  SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET,
} from "../actions/types";
const initialState = {
  legacyWebSocket: null,
  legacyReadyState: null,
  apiGatewayWebSocket: null,
  apiGatewayReadyState: null,
  connectedToAPIGatewayWebSocket: false,
  connectedToAPIAppsyncWebSocket: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LEGACY_WEBSOCKET: {
      return {
        ...state,
        legacyWebSocket: action.payload.socket,
        legacyReadyState:
          action.payload.socket === null
            ? action.payload.readyState
            : action.payload.socket.readyState,
      };
    }

    case SET_API_GATEWAY_WEBSOCKET: {
      return {
        ...state,
        apiGatewayWebSocket: action.payload.socket,
        apiGatewayReadyState: action.payload.readyState,
      };
    }

    case SET_CONNECTED_TO_API_GATEWAY_WEBSOCKET: {
      return {
        ...state,
        connectedToAPIGatewayWebSocket: true,
      };
    }

    case SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET: {
      return {
        ...state,
        connectedToAPIAppsyncWebSocket: action.payload || false,
      };
    }

    default:
      return state;
  }
}
